#filter-popover {
    min-width: 400px;
}

#filter-popover .dropdown-menu {
    position: fixed !important;
    max-height: 400px;
    overflow-y: auto;
}

.dropdown-item.filter {
    background-color: #fff;
}

.dropdown-item.filter.selected-filter,
.dropdown-item.filter:hover {
    background-color: #eceeef;
}
