.project-map .ol-control {
    position: absolute;
    box-shadow: 0 1px 5px rgba(0,0,0,0.65);
    border-radius: 4px;
}

.project-map .ol-hidden {
    display: none;
}

.project-map .ol-control button {
    display: block;
    background-color: #fff;
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    text-decoration: none;
    color: black;
    font: bold 18px 'Lucida Console', Monaco, monospace;
    text-indent: 1px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    padding: 0;
}

.project-map .ol-control button:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.project-map .ol-control button:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: none;
    padding-bottom: 1px;
}

.project-map .ol-zoom {
    bottom: 11px;
    right: 11px;
}

.project-link {
    position: absolute;
    top: 10px;
    left: 20px;
    z-index:  1000;
    
}

.project-link:hover {
    border: 1px dashed;
}